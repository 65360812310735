import React from 'react'
import { Link } from 'react-router-dom'
import { useMediaQuery, useTheme } from '@material-ui/core'
import useStyles from './styles'

import {
  Menu,
  MenuItem,
  ListItem,
  Popover,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@material-ui/core'

export const MenuPopover = ({ menuItems, anchorEl, open, onClose, ...props }) => {

  return (
    <Menu
      id={anchorEl ? 'menu-popover' : undefined}
      open={open}
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      onClose={onClose}
      keepMounted
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    >
      {menuItems.map(item =>
        item.divider ?
          <Divider />
          :
          item.heading ?
            <ListItem>
              <ListItemText primary={item.heading} />
            </ListItem>
            :
            <MenuItem
              component={item.onClick ? "li" : Link}
              to={item.onClick ? undefined : item.linkTo}
              onClick={(e) => {
                if (item.onClick) {
                  item.onClick(e);
                  if (!item?.dontCloseParent) {
                    onClose();
                  } else return;
                } else {
                  if (!item?.dontCloseParent) {
                    onClose();
                  } else return;
                }
              }}
            >
              {item.useSpacer && <>&emsp; &nbsp;</>}
              {item.icon &&
                <>
                  {React.createElement(item.icon, { fontSize: 'small' })}
                  &nbsp;
                </>
              }
              {item.label || item.title}
            </MenuItem>
      )}
    </Menu>
  )
}

export default MenuPopover